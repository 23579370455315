import  type { NavigationGuardNext, RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { goSignInPage } from "@/utils/commands";
import { useAdminStore } from "@/stores/admin";
import { useConfirmStore } from "@/stores/confirm";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/vue-i18n";

const requireAuth =
  () => async (_to: unknown, _from: unknown, next: NavigationGuardNext) => {
    const { loggedIn } = useAdminStore();
    if (!loggedIn) {
      next(false);
      return await goSignInPage();
    }
    return next();
  };
const checkChangedForm =
  () => async (_to: unknown, _from: unknown, next: NavigationGuardNext) => {
    const { formSaved } = storeToRefs(useConfirmStore());
    if (
      !formSaved.value &&
      !confirm(i18n.global.t("common.validation.moveConfirm"))
    ) {
      return next(false);
    }
    formSaved.value = true;
    return next();
  };

const routes = (): RouteRecordRaw[] => {
  const error: RouteRecordRaw[] = [
    {
      path: "",
      component: () => import("@/views/error/UnexpectedPage.vue"),
    },
    {
      path: "404",
      component: () => import("@/views/error/NotFoundPage.vue"),
    },
  ];
  const authPage: RouteRecordRaw[] = [
    {
      path: "request-reset-password",
      component: () => import("@/views/sign/RequestResetPassword.vue"),
    },
    {
      path: "reset-password",
      component: () => import("@/views/sign/ResetPassword.vue"),
    },
    {
      path: "sign-in",
      component: () => import("@/views/sign/in/SignIn.vue"),
    },
  ];
  const inspection: RouteRecordRaw[] = [
    {
      path: "list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/inspection/InspectionPage.vue"),
    },
    {
      path: "newList",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/inspection/newList/InspectionPage.vue"),
    },
    {
      path: "receiving",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/inspection/receiving/InspectionReceivingPage.vue"),
    },
    {
      path: "newReceiving",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
          import("@/views/inspection/newReceiving/InspectionReceivingPage.vue"),
    },
  ];

  // 입고작업
  const receiving_operations: RouteRecordRaw[] = [
    {
      path: "barcode-scan",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/receiving-operations/barcode-scan/BarcodeScanPage.vue"),
    },
    {
      path: "weight-registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/receiving-operations/weight-registration/WeightRegistrationPage.vue"
        ),
    },
    {
      path: "multi-print-waybill",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/receiving-operations/multi-print-waybill/MultiPrintWaybillPage.vue"
        ),
    },
    {
      path: "uni-weight-registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/receiving-operations/uni-weight-registration/UniWeightRegistrationPage.vue"
        ),
    },
    {
      path: "icn-fc-weight-registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/receiving-operations/icn-fc-weight-registration/IcnFcWeightRegistrationPage.vue"
        ),
    },
    {
      path: "remove-waybill",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/receiving-operations/removed/RemovedWaybillPage.vue"),
    },
  ];

  // MAWB
  const mawb: RouteRecordRaw[] = [

    {
      path: "mawbapply",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/mawb/apply/MawbApplyPage.vue"),
    },
    {
      path: "newapply",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/mawb/newApply/MawbApplyPage.vue"),
    },
    {
      path: '/newapply/detail',
      name: 'MawbApplyDetailPage',
      component: () => import("@/views/mawb/newApply/MawbApplyDetailPage.vue"),
    },
    {
      path: '/newapply/statistics',
      name: 'MawbApplyStatisticsPage',
      component: () => import("@/views/mawb/newApply/MawbApplyStatisticsPage.vue"),
    },
    {
      path: '/newapply/history',
      name: 'MawbApplyHistoryPage',
      component: () => import("@/views/mawb/newApply/MawbApplyHistoryPage.vue"),
    },
    {
      path: "mawbapply-scan",
      meta: {
        layout: "simple",
      },
      beforeEnter: [requireAuth()],
      component: () => import("@/views/mawb/apply/MawbApplyScanPage.vue"),
    },
    {
      path: "register-business-weight-by-mawb",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/mawb/register-business-weight-by-mawb/RegisterBusinessWeightByMawbPage.vue"
        ),
    },
  ];

  // 발송내역관리
  const shipping: RouteRecordRaw[] = [
    {
      path: "list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/ShippingPage.vue"),
    },
    {
      path: "integrated-list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/ShippingIntegratedPage.vue"),
    },
    {
      path: "commercial",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/commercial/CommercialPage.vue"),
    },
  ];

  // 재고관리
  const inventory: RouteRecordRaw[] = [
    {
      path: "list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/inventory/InventoryPage.vue"),
    },
  ];

  // 사입관리
  const purchase_manage: RouteRecordRaw[] = [
    {
      path: "take-in-code",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/purchase/take-in-code/TakeInCodePage.vue"),
    },
    {
      path: "take-in-item",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/purchase/take-in-item/TakeInItemPage.vue"),
    },
    {
      path: "take-in-shipment-process",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/purchase/take-in-shipment-process/TakeInShipmentProcessPage.vue"
        ),
    },
    {
      path: "take-in-shipment-process-shipment",
      beforeEnter: [requireAuth()],
      meta: {
        layout: "simple",
      },
      component: () =>
        import(
          "@/views/purchase/take-in-shipment-process/TakeInShipmentProcessTabAggregationShipmentPage.vue"
        ),
    },
    {
      path: "take-in-order-etc",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
          import("@/views/purchase/take-in-order-etc/TakeInOrderEtcPage.vue"),
    },
    {
      path: "inventory-check",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/purchase/inventory-check/InventoryCheckPage.vue"),
    },
      
  ];

  // Invoice
  const invoice: RouteRecordRaw[] = [
    {
      path: "price-plan",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/price-plan/PricePlanPage.vue"),
    },
    {
      path: "currency",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/invoice/currency/InvoiceCurrencyPage.vue"),
    },
    {
      path: "fta-food-quarantine-registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/invoice/fta-food-quarantine-registration/FtaFoodQuarantineRegistrationPage.vue"
        ),
    },
    {
      path: "etc-charge",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/etc-charge/EtcChargePage.vue"),
    },
    {
      path: "apply",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/price-apply/PriceApplyPage.vue"),
    },
    {
      path: "invoice",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/InvoicePage.vue"),
    },
    {
      path: "ar-control",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/ar-control/ArControlPage.vue"),
    },
    {
      path: "ar-confirm",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/ar-confirm/ArConfirmPage.vue"),
    },
    {
      path: "ar-report",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/invoice/ar-report/ArReportPage.vue"),
    },
    {
      path: "aging-report",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/invoice/aging-report/AgingReportPage.vue"),
    },
  ];

  // 거래처관리
  const customer_manage: RouteRecordRaw[] = [
    {
      path: "information",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/customer/CustomerPage.vue"),
    },
    {
      path: "unit-list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/customer/price/CustomerPricePage.vue"),
    },
    {
      path: "promotion",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/customer/coupang-promotion/CoupangPromotionPage.vue"),
    },
    {
      path: "contract",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/customer/contract/CustomerContractPage.vue"),
    },
    {
      path: "hawb-range",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/customer/hawb-range/HawbRangePage.vue"),
    },
  ];

  // System
  const system: RouteRecordRaw[] = [
    {
      path: "user",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/user/UserPage.vue"),
    },
    {
      path: "code",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/code/CodePage.vue"),
    },
    {
      path: "system-setting",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/setting/SystemSettingPage.vue"),
    },
    {
      path: "bl-excel",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/bl-excel/BlExcelPage.vue"),
    },
    {
      path: "my-info",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/my-info/MyInfoPage.vue"),
    },
    {
      path: "station",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/system/station/StationPage.vue"),
    },
  ];

  // 게시판
  const board: RouteRecordRaw[] = [
    {
      path: "qna",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/board/qna/QnaPage.vue"),
    },
    {
      path: "notice",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/board/notice/NoticePage.vue"),
    },
  ];

  return [
    {
      path: "/",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/HomePage.vue"),
      meta: {
        layout: "default",
      },
    },
    // {
    //   path: "/menu",
    //   variables: () => import("@/views/menu/MenuPage.vue"),
    // },
    {
      path: "/inspection",
      children: inspection.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // 입고작업
    {
      path: "/receiving-operations",
      children: receiving_operations.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // MAWB
    {
      path: "/mawb",
      children: mawb.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // 발송내역관리
    {
      path: "/shipping",
      children: shipping.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // 재고목록
    {
      path: "/inventory",
      children: inventory.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // 재고목록
    {
      path: "/purchase",
      children: purchase_manage.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // Invoice
    {
      path: "/invoice",
      children: invoice.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // 거래처관리
    {
      path: "/customer-manage",
      children: customer_manage.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // System
    {
      path: "/system",
      children: system.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    // Board
    {
      path: "/board",
      children: board.map((v) => ({
        ...v,
        meta: {
          layout: v.meta?.layout ?? "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/auth",
      children: authPage.map((v) => ({
        ...v,
        meta: {
          layout: "auth",
        },
      })),
    },
    {
      path: "/error",
      children: error.map((v) => ({
        ...v,
        meta: {
          layout: "error",
        },
      })),
    },
    {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404,
      redirect: "/error/404",
    },
  ];
};

// 라우터 생성
const router = createRouter({
  history: createWebHistory(),
  routes: routes(),
});

export default router;
