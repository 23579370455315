export default {
  ko: {
    cusReqDt: "요청일자",
    cusItemCode: "업체상품코드",
    itemBarcode: "상품바코드",
    netValue: "Net Value",
    wtaKg: "WT(A)\n/(kg)",
    wtcKg: "WT(C)\n/(kg)",
    puchStatus: "사용상태",
    whInCnt: "입고\n수량",
    whOutCnt: "출고\n수량",
    whInDate: "입고일자",
    cusOutNo: "업체출고번호",
    cusInvcNo: "업체 Invoice No.",
    cusSupplier: "업체 Supplier 이름",
    cusSupplierTel: "업체 Supplier 전화번호",
    cusSupplierAddr: "업체 Supplier 주소",
    inspector: "검수자",
    printLabelFlag: "라벨 프린트",
    etcInfo1: "추가정보1",
    etcInfo2: "추가정보2",
    etcInfo3: "추가정보3",

    totIn: "전체입고수량",
    totOut: "전체출고수량",
    totNotUse: "사용불가수량",
    totInventoryItem: "전체재고수량",
    whInventoryCnt: "전체재고수량",
    appDate: "출고일자",
    cusName: "업체명",
    takeInNo: "상품번호 (Barcode No.)",

    targetQt: "대상 건수",
    qtenNo: "Q10 No.",

    takeInCode: {
      takeInCode: "ACI 코드",
      addButtonTitle: "등록 처리",
      removeButtonTitle: "삭제 처리",
      excelButtonTitle: "엑셀 등록",
      manualButtonTitle: "수기 등록",
      addSetItemTitle: "세트 상품 등록",
      setYn:"세트상품여부",

      registerConfirm: "선택한 상품코드 등록처리 하시겠습니까?",
      removeConfirm: "선택한 상품코드 삭제(미사용)처리 하시겠습니까?/n세트상품의 경우 삭제처리됩니다.",
      registerPuchStatusError:
        "사용상태가 '등록요청'인 요청만 등록처리 가능합니다.",
      removePuchStatusError:
        "사용상태가 '미사용요청'인 요청만 삭제처리 가능합니다.",

      editDialog: {
        title: "사입 상품코드",
        wtaKg: "Actual WT",
        wtcKg: "Volume WT",
        createConfirm: "상품코드 등록 하시겠습니까?",
        updateConfirm: "상품코드정보 저장겠습니까?",
        updateConfirmIfHasError:
          "이 상품코드로 이미 운송장이 등록되었습니다. 그래도 수정하시겠습니까?",
      },
      itemDialog: {
        title: "사입 상품 입고",
        createConfirm: "상품 입고등록 하시겠습니까?",
      },
      excelDialog: {
        title: "사입상품코드 엑셀등록",
        confirmExcelUpload:
          "사입상품코드 등록 하시겠습니까?  (오류내역은 제외하고 저장됩니다.)",
      },
      bundleItemDialog: {
        addTitle: "세트상품 등록",
        editTitle: "세트상품 상세",
        bundleItemTitle: "세트 상품 정보",
        itemDetail:"상품명",
        cusItemCode:"업체상품코드",
        hsCode:"HS CODE",
        netValue:"Net Value",
        wtaKg:"Net W/T(kg)",
        wtcKg:"Volume (kg)",
        foodDivi:"식품구분",
        makeCountry:"제조국",
        makeCompany:"제조회사",
        takeInCodeList:"상품목록",
        takeInCodeNetVaule:"단가",
        totStock:"재고수량",
        itemCnt:"묶음수량",
        createConfirm: "세트 상품 등록 하시겠습니까?",
        updateConfirm: "세트 상품 수정 하시겠습니까?"
      },
    },

    takeInItem: {
      addButtonTitle: "입고 등록",
      addLuxuryItemButtonTitle: "명품 등록",
      addDialog: {
        title: "사입 상품 입고",
      },
      historyDialog: {
        title: "사입 상품 입고 내역 수정",
      },
      detailDialog: {
        title: "사입 상품 상세 내역",
        disposal: "폐기",
        return: "반품",
        disposalWarning: "입고상태의 상품만 폐기 처리 가능합니다.",
        disposalConfirm: "선택한 상품 폐기 처리 하시겠습니까?",
        returnWarning: "입고상태의 상품만 반품 처리 가능합니다.",
        returnConfirm: "선택한 상품 반품 처리 하시겠습니까?",
        cancelReturnDisposal: "반품/폐기 취소",
        cancelReturnDisposalWarning:
          "반품 또는 폐기상태 상품만 취소 처리 가능합니다.",
        cancelReturnDisposalConfirm:
          "선택한 상품 반품/폐기 취소  처리 하시겠습니까?",
      },
      luxuryItemDialog: {
        title: "명품등록",
        scan: "상품 스캔",
        itemNo: "상품번호",
        duplicatedItemNo: "중복된 상품번호입니다.",
      },
    },

    takeInShipmentProcess: {
      aggregation: {
        title: "발송처리대상 상품 집계",
        printATypeButtonTitle: "운송장출력-A형",
        printCTypeButtonTitle: "운송장출력-C형",
        printQ10ATypeButtonTitle: "Q10 A형",
        printQ10CTypeButtonTitle: "Q10 C형",
        shipmentButtonTitle: "출고처리",
        commercialButtonTitle: "Commercial",
        excelQ10ShipmentItem: "Q10출고상품 엑셀",
        shipmentDialog: {
          title: "사입상품 출고처리",
          scanNo: "상품번호(Barcode No.)",
          unitCnt: "주문수량",
          scanQty: "스캔수량",
          wtaKg: "실무게 WT(a)",
          wtcKg: "부피무게 WT(c)",
        },
      },
      details: {
        title: "발송처리대상 상품 상세",
      },
      etc:{
        dialogTitle : "기타출고 출고처리",
        takeInStatus: "사용상태",
        orderDate:"출고요청일",
        trkNo: "택배번호",
        trkCom: "택배회사",
        reqCnt: "출고요청수량",
        outCnt: "출고수량",
        adjustFee: "청구비용",
        currency: "청구통화",
        invcStation: "청구스테이션",
        remark: "내용",
        cancelOutgoing: "출고취소",
        outgoing : "출고처리",
      }
    },
    etc :{
      dialogTitle : "기타출고 출고처리",
      addDialogTitle : "기타출고 등록",
      editDialogTitle : "기타출고 상세",
      shipmentDialogTitle : "기타출고 출고처리",
      takeInStatus: "사용상태",
      orderDate:"출고요청일",
      trkNo: "택배번호",
      trkCom: "택배회사",
      reqCnt: "출고요청수량",
      outCnt: "출고수량",
      adjustFee: "청구비용",
      currency: "청구통화",
      invcStation: "청구스테이션",
      remark: "내용",
      cancelOutgoing: "출고취소",
      outgoing : "출고처리",
      etcInfo : "기타출고 정보",
    }
  },
  en: {},
  zhHans: {},
};
