import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
export const DRAWERS: Drawer[] = [
  {
    id: "INSPECTION",
    type: MenuType.GROUP,
    icon: "mdi-package-variant-closed-check",
    children: [
      // {
      //   id: "INSPECTION_LIST",
      //   type: MenuType.PAGE,
      //   url: "/inspection/list",
      // },
      {
        id: "INSPECTION_NEW_LIST",
        type: MenuType.PAGE,
        url: "/inspection/newList",
      },
      // {
      //   id: "INSPECTION_RECEIVING",
      //   type: MenuType.PAGE,
      //   url: "/inspection/receiving",
      // },
      {
        id: "INSPECTION_RECEIVING_NEW",
        type: MenuType.PAGE,
        url: "/inspection/newReceiving",
      },
    ],
  },
  {
    id: "RECEIVING_OPERATIONS",
    type: MenuType.GROUP,
    icon: "mdi-store-plus",
    children: [
      {
        id: "RECEIVING_OPERATIONS_BARCODE_SCAN",
        type: MenuType.PAGE,
        url: "/receiving-operations/barcode-scan",
      },
      {
        id: "RECEIVING_OPERATIONS_WEIGHT_REGISTRATION",
        type: MenuType.PAGE,
        url: "/receiving-operations/weight-registration",
      },
      {
        id: "RECEIVING_OPERATIONS_MULTI_PRINT_WAYBILL",
        type: MenuType.PAGE,
        url: "/receiving-operations/multi-print-waybill",
      },
      {
        id: "RECEIVING_OPERATIONS_UNI_WEIGHT_REGISTRATION",
        type: MenuType.PAGE,
        url: "/receiving-operations/uni-weight-registration",
      },
      {
        id: "RECEIVING_OPERATIONS_ICN_FC_WEIGHT_REGISTRATION",
        type: MenuType.PAGE,
        url: "/receiving-operations/icn-fc-weight-registration",
      },
      {
        id: "RECEIVING_OPERATIONS_REMOVE_WAYBILL",
        type: MenuType.PAGE,
        url: "/receiving-operations/remove-waybill",
      },
    ],
  },
  {
    id: "MAWB",
    type: MenuType.GROUP,
    icon: "mdi-file-document-outline",
    children: [
      // {
      //   id: "MAWB_APPLY",
      //   type: MenuType.PAGE,
      //   url: "/mawb/mawbapply",
      // },
      {
        id: "MAWB_APPLY_NEW",
        type: MenuType.PAGE,
        url: "/mawb/newapply",
      },
      {
        id: "MAWB_REGISTER_BUSINESS_WEIGHT_BY_MAWB",
        type: MenuType.PAGE,
        url: "/mawb/register-business-weight-by-mawb",
      },
    ],
  },
  {
    id: "SHIPPING",
    type: MenuType.GROUP,
    icon: "mdi-receipt-text-send-outline",
    children: [
      {
        id: "SHIPPING_LIST",
        type: MenuType.PAGE,
        url: "/shipping/list",
      },
      {
        id: "SHIPPING_INTEGRATED_SHIPPING_LIST",
        type: MenuType.PAGE,
        url: "/shipping/integrated-list",
      },
      {
        id: "SHIPPING_COMMERCIAL",
        type: MenuType.PAGE,
        url: "/shipping/commercial",
      },
    ],
  },
  {
    id: "INVENTORY",
    type: MenuType.GROUP,
    icon: "mdi-contrast-box",
    children: [
      {
        id: "INVENTORY_LIST",
        type: MenuType.PAGE,
        url: "/inventory/list",
      },
    ],
  },
  {
    id: "PURCHASE",
    type: MenuType.GROUP,
    icon: "mdi-basket-outline",
    children: [
      {
        id: "PURCHASE_TAKE_IN_CODE",
        type: MenuType.PAGE,
        url: "/purchase/take-in-code",
      },
      {
        id: "PURCHASE_TAKE_IN_ITEM",
        type: MenuType.PAGE,
        url: "/purchase/take-in-item",
      },
      {
        id: "PURCHASE_TAKE_IN_SHIPMENT_PROCESS",
        type: MenuType.PAGE,
        url: "/purchase/take-in-shipment-process",
      },
      {
        id: "PURCHASE_TAKE_IN_ORDER_ETC",
        type: MenuType.PAGE,
        url: "/purchase/take-in-order-etc",
      },

      // {
      //   id: "PURCHASE_INVENTORY_CHECK",
      //   type: MenuType.PAGE,
      //   url: "/purchase/inventory-check",
      // },
    ],
  },
  {
    id: "INVOICE",
    type: MenuType.GROUP,
    icon: "mdi-receipt-text-check-outline",
    children: [
      {
        id: "INVOICE_PRICE_PLAN",
        type: MenuType.PAGE,
        url: "/invoice/price-plan",
      },
      // {
      //   id: "INVOICE_DESTINATION_SHIPPING_COST",
      //   type: MenuType.PAGE,
      //   url: "/invoice/destination-shipping-cost",
      // },
      {
        id: "INVOICE_CURRENCY",
        type: MenuType.PAGE,
        url: "/invoice/currency",
      },
      {
        id: "INVOICE_FTA_FOOD_QUARANTINE_REGISTRATION",
        type: MenuType.PAGE,
        url: "/invoice/fta-food-quarantine-registration",
      },
      {
        id: "INVOICE_ETC_CHARGE",
        type: MenuType.PAGE,
        url: "/invoice/etc-charge",
      },
      {
        id: "INVOICE_PRICE_APPLY",
        type: MenuType.PAGE,
        url: "/invoice/apply",
      },
      {
        id: "INVOICE_INVOICE",
        type: MenuType.PAGE,
        url: "/invoice/invoice",
      },
      {
        id: "INVOICE_CONTROL",
        type: MenuType.PAGE,
        url: "/invoice/ar-control",
      },
      {
        id: "INVOICE_CONFIRM",
        type: MenuType.PAGE,
        url: "/invoice/ar-confirm",
      },
      {
        id: "INVOICE_REPORT",
        type: MenuType.PAGE,
        url: "/invoice/ar-report",
      },
      {
        id: "INVOICE_AGE",
        type: MenuType.PAGE,
        url: "/invoice/aging-report",
      },
    ],
  },
  {
    id: "CUSTOMER",
    type: MenuType.GROUP,
    icon: "mdi-office-building-cog-outline",
    children: [
      {
        id: "CUSTOMER_INFO",
        type: MenuType.PAGE,
        url: "/customer-manage/information",
      },
      {
        id: "CUSTOMER_PRICE",
        type: MenuType.PAGE,
        url: "/customer-manage/unit-list",
      },
      {
        id: "CUSTOMER_PROMOTION",
        type: MenuType.PAGE,
        url: "/customer-manage/promotion",
      },
      {
        id: "CUSTOMER_CONTRACT",
        type: MenuType.PAGE,
        url: "/customer-manage/contract",
      },
      {
        id: "CUSTOMER_HAWB_RANGE",
        type: MenuType.PAGE,
        url: "/customer-manage/hawb-range",
      },
    ],
  },
  {
    id: "SYSTEM",
    type: MenuType.GROUP,
    icon: "mdi-cog",
    children: [
      {
        id: "SYSTEM_USER",
        type: MenuType.PAGE,
        url: "/system/user",
      },
      {
        id: "SYSTEM_CODE",
        type: MenuType.PAGE,
        url: "/system/code",
      },
      {
        id: "SYSTEM_MENU",
        type: MenuType.PAGE,
        url: "/system/system-setting",
      },
      {
        id: "SYSTEM_BL_EXCEL",
        type: MenuType.PAGE,
        url: "/system/bl-excel",
      },
      {
        id: "SYSTEM_STATION",
        type: MenuType.PAGE,
        url: "/system/station",
      },
      {
        id: "SYSTEM_MY_INFO",
        type: MenuType.PAGE,
        url: "/system/my-info",
      },
    ],
  },
  {
    id: "BOARD",
    type: MenuType.GROUP,
    icon: "mdi-bulletin-board",
    children: [
      {
        id: "BOARD_QNA",
        type: MenuType.PAGE,
        url: "/board/qna",
      },
      {
        id: "BOARD_NOTICE",
        type: MenuType.PAGE,
        url: "/board/notice",
      },
    ],
  },
];
